<template>
  <div>
  <div class="top-bar2">
      <div class="account-title">Create Order</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
